import { styles } from './styles';
import axios from 'axios';
import {
  Textarea,
  Button,
  Loader,
  Tooltip,
  NumberInput,
  Select,
  MultiSelect,
  LoadingOverlay,
  Stack,
  Group,
  TextInput,
  SegmentedControl,
  Text,
  Paper,
  Divider,
  Title,
  Box,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import {
  removeFile,
  getFilelist,
  notifyFileUpload,
  getPresignedURLs,
} from '@/services/removeFiles';
import {
  Proposal,
  FileMetadata,
  PresignedURL,
  GetPresignedURLsResponse,
  OtherData,
} from '@/interfaces/types';
import { compact } from 'lodash';
import EditableTable from '../../components/Tables/EditableTable';
import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchExpertise } from '@/services/proposalsRoutes';
import { Input } from 'postcss';

export default function Requirements({
  proposal,
  dispatchProposal,
  userFeedback,
  dispatchUserFeedback,
  otherData,
  dispatchOtherData,
}: {
  proposal: Proposal;
  dispatchProposal: React.Dispatch<any>;
  userFeedback: any;
  dispatchUserFeedback: React.Dispatch<any>;
  otherData: OtherData;
  dispatchOtherData: React.Dispatch<any>;
}) {
  const { title, input, sector, constraints } = proposal;
  const { allLawyers, expertise, seniority, documentLanguage } = otherData;
  const { loading } = userFeedback;
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [isLLMLoading, setIsLLMLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getExpertise() {
      const accessToken = await getAccessTokenSilently();
      const response = await fetchExpertise(accessToken);
      dispatchOtherData({
        field: 'expertise',
        value: ['Any', ...response],
      });
    }
    getExpertise();
  }, []);

  const handleRequiredLawyersChange = (values: string[]) => {
    dispatchProposal({
      type: PROPOSALACTIONS.SET_CONSTRAINTS,
      field: 'requiredLawyers',
      value: values,
    });
  };

  return (
    <Paper
      // withBorder shadow="sm" radius="md" p="md"
      style={{ width: '100%', marginTop: 0 }}
      p="md"
    >
      <Stack gap="sm">
        {/* Title row */}
        {/* <Group>
          <Title order={3}>{t('constraints')}</Title>
          <Tooltip label={t('provideConstraints')} withArrow multiline w={220} position="right">
            <IconInfoCircle
              size={20}
              color="var(--mantine-primary-color-filled)"
              style={{ marginTop: 2 }}
            />
          </Tooltip>
        </Group> */}

        {/* Team Composition */}
        <Group gap="0">
          <Text fw={600} size="sm" mr="0.5rem">
            {t('teamComposition')}
          </Text>
          <Tooltip label={t('provideTeamComposition')} withArrow multiline w={220} position="right">
            <IconInfoCircle
              size={16}
              color="var(--mantine-primary-color-filled)"
              style={{ marginBottom: 2 }}
            />
          </Tooltip>
        </Group>
        {/* <Paper
          w="100%"
          radius="md"
          style={{
            margin: '0 auto',
            border: '1px solid #ccc',
            padding: '1rem',
          }}
        > */}
        {/* <InputGroup title={t('teamComposition')} description={t('provideTeamComposition')}> */}
        <EditableTable
          rows={constraints?.teamComposition || []}
          columns={[
            {
              title: t('expertise'),
              key: 'team',
              type: 'select',
              selectValues: expertise,
              exclusiveSelect: true,
            },
            {
              title: t('quantity'),
              key: 'quantity',
              type: 'number',
              width: '100px',
            },
          ]}
          dispatchData={dispatchProposal}
          dispatchAddAction={PROPOSALACTIONS.ADD_TEAM_CONSTRAINT}
          dispatchRemoveAction={PROPOSALACTIONS.REMOVE_TEAM_CONSTRAINT}
        />
        {/* </InputGroup> */}

        {/* Required Lawyers */}
        <InputGroup title={t('requiredLawyers')} description={t('requiredLawyersDescription')}>
          <MultiSelect
            data={allLawyers.map((lawyer) => lawyer.LawyerName)}
            value={(constraints && constraints.requiredLawyers) || []}
            onChange={handleRequiredLawyersChange}
            placeholder={t('selectRequiredLawyers')}
            searchable
            hidePickedOptions
          />
        </InputGroup>
        {/* <Group gap={0} align="end">
          <Text fw={600} size="sm" mr="0.5rem">
            {t('requiredLawyers')}
          </Text>
          <Tooltip
            label={t('requiredLawyersDescription')}
            withArrow
            multiline
            w={220}
            position="right"
          >
            <IconInfoCircle
              size={16}
              color="var(--mantine-primary-color-filled)"
              style={{ marginBottom: 2 }}
            />
          </Tooltip>
        </Group>
        <MultiSelect
          data={Object.keys(allLawyers)}
          value={(constraints && constraints.requiredLawyers) || []}
          onChange={handleRequiredLawyersChange}
          placeholder={t('selectRequiredLawyers')}
          searchable
          w="100%"
          hidePickedOptions
        /> */}

        {/* Seniority */}
        {/* Number of Experiences */}
        <InputGroup
          title={t('numberOfExperiences')}
          description={t('experienceNumbersDescription')}
        >
          <NumberInput
            min={0}
            style={{ maxWidth: '25%' }}
            value={proposal.constraints?.nExperiences ?? 5}
            onChange={(value) => {
              dispatchProposal({
                type: PROPOSALACTIONS.ADD_N_EXPERIENCE_CONSTRAINT,
                payload: value,
              });
            }}
          />
        </InputGroup>

        {/* <Group gap={0} align="end">
          <Text fw={600} size="sm" mr="0.5rem">
            {t('numberOfExperiences')}
          </Text>
          <Tooltip label={t('provideTeamComposition')} withArrow multiline w={220} position="right">
            <IconInfoCircle
              size={16}
              color="var(--mantine-primary-color-filled)"
              style={{ marginBottom: 2 }}
            />
          </Tooltip>
        </Group>
        <NumberInput
          min={1}
          style={{ maxWidth: '25%' }}
          value={proposal.constraints?.nExperiences ?? 5}
          onChange={(value) => {
            dispatchProposal({
              type: PROPOSALACTIONS.ADD_N_EXPERIENCE_CONSTRAINT,
              payload: value,
            });
          }}
        /> */}
      </Stack>
    </Paper>
  );
}

function InputGroup({
  title,
  description,
  children,
  width,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
  width?: string;
}) {
  return (
    <Group gap="sm" align="center">
      <Group gap={0} align="end" w={width || '30%'}>
        <Text fw={600} size="sm" mr="0.5rem">
          {title}
        </Text>
        <Tooltip label={description} withArrow multiline w={220} position="right">
          <IconInfoCircle
            size={16}
            color="var(--mantine-primary-color-filled)"
            style={{ marginBottom: 2 }}
          />
        </Tooltip>
      </Group>
      <Box w="65%">{children}</Box>
    </Group>
  );
}
