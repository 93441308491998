import { AppShell, Burger, Group, Skeleton, Text, Code, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MantineLogo } from '@mantinex/mantine-logo';
import NavbarSimple from '@/components/NavBar/NavBar';
import { Outlet } from 'react-router-dom';
import WKBLogo from '@/assets/WKB_logo.png';

export default function Root() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
      layout="default"
    >
      <AppShell.Header style={{ backgroundColor: '#555' }}>
        <Group h="100%" px="md">
          <img src={WKBLogo} height="40" />
          <Code fw={700}>v3.2.2</Code>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <NavbarSimple />
      </AppShell.Navbar>
      <AppShell.Main>
        <Stack align="center">
          <Outlet />
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
