import {
  Table,
  Anchor,
  Button,
  Input,
  Center,
  NumberInput,
  TextInput,
  Select,
} from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProposalList, deleteProposal } from '@/services/proposalsRoutes';
import { IconX, IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
import { useTranslation } from 'react-i18next';

// A componenet that generates a table row when given a row from proposalData
function ProposalRow({
  row,
  dispatchData,
  dispatchRemoveAction,
  columns,
}: {
  row: { [key: string]: any };
  dispatchData: React.Dispatch<any>;
  dispatchRemoveAction: string;
  columns: { title: string; key: string; width?: string }[];
}) {
  const handleDeleteClick = () => {
    async function deleteProposalData() {
      dispatchData({ type: dispatchRemoveAction, payload: row });
    }
    deleteProposalData();
  };

  return (
    <Table.Tr>
      {columns.map((column) => {
        return (
          <Table.Td key={column.key} style={{ width: column.width }}>
            {row[column.key]}
          </Table.Td>
        );
      })}

      <Table.Td style={{ textAlign: 'right' }}>
        <Center>
          <Button variant="light" color="red" size="compact-xs" onClick={handleDeleteClick}>
            <IconX size={16} />
          </Button>
        </Center>
      </Table.Td>
    </Table.Tr>
  );
}

type miscRow = { [key: string]: string | number | undefined | null };
type column = {
  title: string;
  key: string;
  type?: string;
  selectValues?: string[];
  exclusiveSelect?: boolean;
  width?: string; // new optional property
};

export default function EditableTable({
  rows,
  dispatchData,
  dispatchAddAction,
  dispatchRemoveAction,
  columns,
}: {
  rows: miscRow[] | undefined;
  dispatchData: React.Dispatch<any>;
  dispatchAddAction: string;
  dispatchRemoveAction: string;
  columns: column[];
}) {
  const { t } = useTranslation();

  const initialRow: miscRow = {};
  // Iterate through columns and set defaults based on type
  columns.forEach((column) => {
    if (column.type === 'select') {
      initialRow[column.key] = null; // Default for select columns
    } else if (column.type === 'number') {
      initialRow[column.key] = 1; // Default for number columns
    } else {
      initialRow[column.key] = null; // Default for other types (e.g., text)
    }
  });

  const [newRow, setNewRow] = useState<miscRow>(initialRow);

  const handleAddRow = () => {
    dispatchData({ type: dispatchAddAction, payload: newRow });
    setNewRow(initialRow);
  };

  const changeHandlers = columns.map((column) => {
    if (column.type === 'number') {
      return (e: number | string) => {
        setNewRow({ ...newRow, [column.key]: e });
      };
    } else if (column.type == 'select') {
      return (e: string | null) => {
        if (e == newRow[column.key]) {
          setNewRow((prev) => ({ ...prev, [column.key]: null }));
        } else if (e) {
          setNewRow((prev) => ({ ...prev, [column.key]: e }));
        }
      };
    } else {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRow({ ...newRow, [column.key]: e.target.value });
      };
    }
  });

  const getSelectData = (column: column) => {
    if (column.exclusiveSelect && column.selectValues) {
      return column.selectValues.filter((d) => {
        if (rows) {
          for (let row of rows) {
            if (row[column.key] === d) {
              return false;
            }
          }
        }
        return true;
      });
    } else {
      return column.selectValues;
    }
  };

  // const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewRow({ ...newRow, position: e.target.value });
  // };

  // const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewRow({ ...newRow, rate: parseFloat(e.target.value) });
  // };

  return (
    <Table.ScrollContainer minWidth={400} w="100%">
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            {columns.map((column) => (
              <Table.Th key={column.key} style={{ width: column.width }}>
                {column.title}
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows &&
            rows.map((row) => (
              <ProposalRow
                key={row[columns[0].key]}
                row={row}
                dispatchRemoveAction={dispatchRemoveAction}
                dispatchData={dispatchData}
                columns={columns}
              />
            ))}
          <Table.Tr>
            {columns.map((column, idx) => {
              if (column.type === 'number') {
                return (
                  <Table.Td key={column.key} style={{ width: column.width }}>
                    <NumberInput
                      // @ts-ignore  typescript just happens to be wrong in this case
                      value={newRow[columns[idx].key]}
                      // @ts-ignore  typescript just happens to be wrong in this case
                      onChange={changeHandlers[idx]}
                      min={1}
                    />
                  </Table.Td>
                );
              }
              if (column.type === 'select') {
                return (
                  <Table.Td key={column.key} style={{ width: column.width }}>
                    <Select
                      data={getSelectData(column)}
                      //   @ts-ignore
                      value={newRow[columns[idx].key]}
                      //   @ts-ignore
                      onChange={changeHandlers[idx]}
                      searchable
                    />
                  </Table.Td>
                );
              } else {
                return (
                  <Table.Td key={column.key} style={{ width: column.width }}>
                    {/* @ts-ignore */}
                    <TextInput value={newRow[columns[idx].key]} onChange={changeHandlers[idx]} />
                  </Table.Td>
                );
              }
            })}
            {/* <Table.Td>
              <Input type="number" value={newRow[columns[1].key]} onChange={changeHandlers} />
            </Table.Td> */}
            <Table.Td>
              <Center>
                <Button variant="light" color="green" size="sm" onClick={handleAddRow}>
                  {t('addRow')}
                </Button>
              </Center>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
