import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  Title,
  Tooltip,
  TextInput,
  Textarea,
  Input,
  MultiSelect,
  ScrollArea,
  Stack,
} from '@mantine/core';

import FeesSection from './FeesSection';
import EditableTable from '../../components/Tables/EditableTable';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { Proposal } from '@/interfaces/types';
import { styles } from './styles';
import Dropzone from 'react-dropzone';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
import ExpandingTextArea from '@/components/ExpandingTextArea';

export default function HumanGeneratedSection({
  proposal,
  dispatchProposal,
  userFeedback,
  dispatchUserFeedback,
  otherData,
  dispatchOtherData,
}: {
  proposal: Proposal;
  dispatchProposal: React.Dispatch<any>;
  userFeedback: any;
  dispatchUserFeedback: React.Dispatch<any>;
  otherData: any;
  dispatchOtherData: React.Dispatch<any>;
}) {
  const { t, i18n } = useTranslation();

  const {
    client,
    introDefault,
    intro,
    scope,
    assumptions,
    fees,
    representatives,
    experienceIntro,
  } = proposal;

  const { allLawyers } = otherData;
  const { documentLanguage } = otherData;

  //   const getAvailableLawyers = (currentLawyerId: string) => {
  //     const selectedLawyerIds = new Set(Object.keys(selectedLawyers));
  //     return Object.keys(allLawyers)
  //       .filter((key) => key === currentLawyerId || !selectedLawyerIds.has(key))
  //       .map((key) => ({
  //         value: key,
  //         label: allLawyers[key],
  //       }));
  //   };

  const handleRepresentativesChange = (values: string[]) => {
    dispatchProposal({
      type: PROPOSALACTIONS.SET_FIELD,
      field: 'representatives',
      value: values,
    });
  };

  return (
    <Stack gap="0" p="md" w="100%">
      {/* <div style={styles.flexRow}>
        <Title order={3}>{t('lawyerInput')}</Title>
        <Tooltip label={t('humanInput')} withArrow position="right" w={220} multiline>
          <IconInfoCircle size={24} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div> */}
      {/* Client Information */}

      {/* Introduction */}
      <Title order={4} mt="lg" ml="sm" mb="2px">
        {t('introduction')}
      </Title>
      <ExpandingTextArea
        value={intro}
        placeholder={t('addYourIntroHere')}
        onChange={(e) =>
          dispatchProposal({
            type: PROPOSALACTIONS.SET_FIELD,
            field: 'intro',
            value: e.target.value,
          })
        }
      />

      {/* Representatives */}
      <div style={styles.flexRow}>
        <Title order={4} mt="lg" ml="sm" mb="2px">
          {t('wkbRep')}
        </Title>
        <Tooltip label={t('representativesTooltip')} withArrow multiline position="right" w={220}>
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      <MultiSelect
        // label={t('representatives')}
        data={allLawyers.map((lawyer: any) => lawyer.LawyerName)}
        value={representatives || []}
        onChange={(values) => {
          handleRepresentativesChange(values);
        }}
        placeholder={t('selectRepresentatives')}
        searchable
      />
      {/* <EditableTable
        rows={representatives}
        columns={[
          { title: 'Name and Surname', key: 'name' },
          { title: 'Position', key: 'position' },
        ]}
        dispatchData={dispatchProposal}
        dispatchAddAction={PROPOSALACTIONS.ADD_REPRESENTATIVE}
        dispatchRemoveAction={PROPOSALACTIONS.REMOVE_REPRESENTATIVE}
      /> */}

      {/* Scope of Work */}
      <Title order={4} mt="lg" ml="sm" mb="2px">
        {t('scopeOfWork')}
      </Title>
      <ExpandingTextArea
        value={scope}
        placeholder={t('addScopeOfWorkHere')}
        onChange={(e) =>
          dispatchProposal({
            type: PROPOSALACTIONS.SET_FIELD,
            field: 'scope',
            value: e.target.value,
          })
        }
      />

      {/* Assumptions */}
      <Title order={4} mt="lg" ml="sm" mb="2px">
        {t('assumptions')}
      </Title>
      <ExpandingTextArea
        value={assumptions}
        placeholder={t('addAssumptionsHere')}
        onChange={(e) =>
          dispatchProposal({
            type: PROPOSALACTIONS.SET_FIELD,
            field: 'assumptions',
            value: e.target.value,
          })
        }
      />

      <FeesSection fees={fees} dispatchProposal={dispatchProposal} />

      {/* Experience Introduction */}
      <Title order={4} mt="lg" ml="sm" mb="2px">
        {t('experienceIntro')}
      </Title>
      <ExpandingTextArea
        value={experienceIntro}
        placeholder={t('addExperienceIntroHere')}
        onChange={(e) =>
          dispatchProposal({
            type: PROPOSALACTIONS.SET_FIELD,
            field: 'experienceIntro',
            value: e.target.value,
          })
        }
      />
    </Stack>
  );
}

// Utility function to check intro changes
function checkIntroChangedTooMuch(
  intro: string,
  introDefault: string,
  line1: string,
  theRest: string
) {
  const isLine1Present = intro.includes(line1);
  const isRestPresent = intro.includes(theRest);
  const isLengthSimilar = Math.abs(intro.length - introDefault.length) < 60;

  return !(isLine1Present && isRestPresent && isLengthSimilar);
}

const introLetterEng = `
    Dear _____
    
    thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for _____.
    
    In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.
    
    In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 
    
    If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.
    
    Kind regards,
    `;

const introLetterPol = `
  Szanowna Pani/Panie _____
  
  dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]
  
  W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.
  
  W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.
  
  W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.
  
  Z poważaniem`;
