import React, { useState, useEffect } from 'react';
import { Table, Select, Button, Group, LoadingOverlay, Card, useMantineTheme } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { authWebSocket } from '@/auth/authFetches';
import { useAuth0 } from '@auth0/auth0-react';
import { Proposal } from '@/interfaces/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconGripVertical } from '@tabler/icons-react';
import ExpandingTextArea from '@/components/ExpandingTextArea';

type LawyerTableProps = {
  allLawyers: { LawyerName: string; Honorific: string }[];
  selectedLawyers: { name: string; bio: string }[];
  dispatchProposal: React.Dispatch<React.SetStateAction<{ type: string; payload: any }>>;
  otherData: any;
  proposal: Proposal;
};

const LawyerTable: React.FC<LawyerTableProps> = ({
  allLawyers,
  proposal,
  otherData,
  selectedLawyers,
  dispatchProposal,
}) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { documentLanguage } = otherData;
  const { input } = proposal;

  // State for maxWidth of dropdown, active WebSocket and loading state
  const [maxWidth, setMaxWidth] = useState<string>('auto');
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
  console.log(selectedLawyers);

  useEffect(() => {
    const longestName = allLawyers
      .map((lawyer) => lawyer.LawyerName)
      .reduce((longest, current) => (current.length > longest.length ? current : longest), '');
    setMaxWidth(`${longestName.length * 8}px`);
  }, [allLawyers]);

  useEffect(() => {
    const timeouts = Object.keys(loadingRows).map((key) =>
      setTimeout(() => {
        setLoadingRows((prev) => ({ ...prev, [key]: false }));
      }, 15000)
    );
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [loadingRows]);

  const handleLawyerAddition = (lawyerName: string) => {
    dispatchProposal({
      type: PROPOSALACTIONS.ADD_LAWYER,
      payload: { name: lawyerName, bio: '' },
    });
    setLoadingRows((prev) => ({ ...prev, [lawyerName]: true }));
    handleGetLawyerBio(lawyerName);
  };

  const handleSelectChange = (lawyerName: string, rowIndex: number) => {
    setLoadingRows((prev) => ({ ...prev, [lawyerName]: true }));
    dispatchProposal({
      type: PROPOSALACTIONS.EDIT_LAWYER,
      payload: { newName: lawyerName, oldName: selectedLawyers[rowIndex].name },
    });
    handleGetLawyerBio(lawyerName);
  };

  const handleBioChange = (lawyerName: string, bio: string) => {
    dispatchProposal({
      type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
      payload: { name: lawyerName, bio },
    });
  };

  const removeRow = (lawyerName: string) => {
    dispatchProposal({ type: PROPOSALACTIONS.REMOVE_LAWYER, payload: lawyerName });
  };

  const getAvailableLawyers = (currentLawyerId: string) => {
    const selectedNames = selectedLawyers.map((lawyer) => lawyer.name);
    return allLawyers
      .map((lawyer) => lawyer.LawyerName)
      .filter((name) => name === currentLawyerId || !selectedNames.includes(name))
      .map((id) => ({ value: id, label: id }));
  };

  const handleLawyerGenerationMessage = (message: any) => {
    if (message.type === 'selected_lawyers') {
      message.data.forEach((lawyerName: string) => {
        if (!(lawyerName in selectedLawyers)) {
          dispatchProposal({
            type: PROPOSALACTIONS.ADD_LAWYER,
            payload: { bio: '', name: lawyerName },
          });
        }
      });
    } else if (message.type === 'bio_response') {
      const { lawyer, bio } = message.data;
      dispatchProposal({
        type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
        payload: { name: lawyer, bio },
      });
    } else if (message.type === 'completed') {
      ws?.close();
    }
  };

  const handleGetLawyerBio = (lawyerName: string) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const newWs = authWebSocket(accessToken);
        setWs(newWs);
        newWs.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.source === 'offer_creation_lawyers') {
            handleLawyerGenerationMessage(data);
            if (data.type === 'bio_response') {
              setLoadingRows((prev) => ({ ...prev, [lawyerName]: false }));
            }
          }
        };
        newWs.onopen = () => {
          newWs.send(
            JSON.stringify({
              action: 'generateLawyers',
              lawyer_names: [lawyerName],
              language: documentLanguage,
              query: input,
            })
          );
        };
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
      }
    })();
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
    const oldIndex = selectedLawyers.findIndex((lawyer) => lawyer.name === active.id);
    const newIndex = selectedLawyers.findIndex((lawyer) => lawyer.name === over.id);
    if (oldIndex === -1 || newIndex === -1) return;
    const reorderedLawyers = arrayMove(selectedLawyers, oldIndex, newIndex);
    dispatchProposal({
      type: PROPOSALACTIONS.REORDER_LAWYERS,
      payload: reorderedLawyers,
    });
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext
        items={selectedLawyers.map((lawyer) => lawyer.name)}
        strategy={verticalListSortingStrategy}
      >
        <Table>
          <tbody>
            {selectedLawyers.map((lawyer, index) => (
              <SortableLawyerRow key={lawyer.name} id={lawyer.name}>
                {({ listeners, attributes }) => (
                  <>
                    <Group gap="xs" align="center">
                      <Select
                        placeholder={t('pickALawyer')}
                        data={getAvailableLawyers(lawyer.name)}
                        value={lawyer.name}
                        onChange={(value) => handleSelectChange(value || '', index)}
                        searchable
                        style={{ width: maxWidth, marginBottom: '0.5rem' }}
                      />
                      {/* Drag handle uses the passed listeners/attributes */}
                      <div
                        style={{ cursor: 'grab', padding: '0.5rem' }}
                        {...listeners}
                        {...attributes}
                      >
                        <IconGripVertical size={20} />
                      </div>
                    </Group>

                    <div style={{ position: 'relative' }}>
                      <LoadingOverlay
                        visible={loadingRows[lawyer.name]}
                        zIndex={1}
                        overlayProps={{ radius: 'sm' }}
                      />
                      <ExpandingTextArea
                        value={selectedLawyers[index]?.bio || ''}
                        placeholder={loadingRows[lawyer.name] ? t('loading') : t('addBio')}
                        onChange={(event) => handleBioChange(lawyer.name, event.target.value || '')}
                        disabled={loadingRows[lawyer.name]}
                      />
                    </div>

                    <Button
                      variant="light"
                      color="red"
                      size="compact-xs"
                      onPointerDown={(e) => e.stopPropagation()}
                      onClick={() => removeRow(lawyer.name)}
                      style={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                      }}
                    >
                      <IconX size={16} />
                    </Button>
                  </>
                )}
              </SortableLawyerRow>
            ))}
            <AdditionalLawyers
              availableLawyers={getAvailableLawyers('')}
              handleLawyerAddition={handleLawyerAddition}
            />
          </tbody>
        </Table>
      </SortableContext>
    </DndContext>
  );
};

export default LawyerTable;
type SortableLawyerRowProps = {
  id: string;
  children: (dragHandleProps: { listeners: any; attributes: any }) => React.ReactNode;
};

export function SortableLawyerRow({ id, children }: SortableLawyerRowProps) {
  // Call useSortable only once per row
  const { setNodeRef, transform, transition, isDragging, listeners, attributes } = useSortable({
    id,
  });
  const theme = useMantineTheme();

  const rowStyle: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
  };

  return (
    <tr ref={setNodeRef} style={rowStyle}>
      <td>
        <Card
          withBorder
          my="xs"
          style={{
            position: 'relative',
            padding: '1rem',
          }}
        >
          {children({ listeners, attributes })}
        </Card>
      </td>
    </tr>
  );
}

export function AdditionalLawyers({
  availableLawyers,
  handleLawyerAddition,
}: {
  availableLawyers: { value: string; label: string }[];
  handleLawyerAddition: (lawyerName: string) => void;
}) {
  const { t } = useTranslation();
  const [additionalLawyers, setAdditionalLawyers] = useState<number>(0);
  const add = () => setAdditionalLawyers((prev) => prev + 1);
  const remove = () => setAdditionalLawyers((prev) => Math.max(0, prev - 1));

  return (
    <>
      {Array.from({ length: additionalLawyers }).map((_, index) => (
        <tr key={index}>
          <td>
            <Group>
              <Select
                placeholder={t('pickALawyer')}
                data={availableLawyers}
                onChange={(value) => {
                  handleLawyerAddition(value || '');
                  remove();
                }}
                searchable
              />
            </Group>
            <ExpandingTextArea
              value=""
              placeholder={t('addBio')}
              disabled={true}
              onChange={() => {}}
            />
          </td>
        </tr>
      ))}
      <Button variant="light" onClick={add} mt="md">
        {t('addLawyer')}
      </Button>
    </>
  );
}
