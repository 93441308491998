import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MobileRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth < 768) {
        // Adjust threshold as needed
        navigate('/mobile-not-supported');
      }
    };

    // Run check on mount
    checkScreenSize();

    // Optionally, listen for window resizes
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, [navigate]);

  return null; // This component does not render anything
}
