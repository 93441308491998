import React from 'react';
import { useState } from 'react';
import FeeTable from './FeeTable';
import { useTranslation } from 'react-i18next';
import { Group, NumberInput, Select } from '@mantine/core';
import { FeeProposal } from './types';
import { PROPOSALACTIONS } from './reducers/proposalReducer';

export default function FeesSection({
  fees,
  dispatchProposal,
}: {
  fees: FeeProposal;
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();
  const feeTypeMap = {
    'Hourly Rate': t('hourly'),
    Retainer: t('retainer'),
    'Lump Sum': t('lumpSum'),
    'Blended Rate': t('blendedRate'),
  };

  return (
    <>
      <h2>{t('fees')}</h2>
      <Group w="100%" gap="lg" align="flex-end" mb="md">
        <Select
          label={t('feeType')}
          data={[
            { value: 'Hourly Rate', label: feeTypeMap['Hourly Rate'] },
            { value: 'Retainer', label: feeTypeMap['Retainer'] },
            { value: 'Lump Sum', label: feeTypeMap['Lump Sum'] },
            { value: 'Blended Rate', label: feeTypeMap['Blended Rate'] },
          ]}
          onChange={(value) =>
            dispatchProposal({ type: PROPOSALACTIONS.EDIT_FEES, field: 'activeFee', value })
          }
          defaultValue={t('lumpSum')}
        />
        <Select
          label={t('currency')}
          data={['USD', 'EUR', 'GBP', 'PLN']}
          onChange={(value) =>
            dispatchProposal({ type: PROPOSALACTIONS.EDIT_FEES, field: 'currency', value })
          }
          defaultValue="EUR"
          allowDeselect={false}
        />
      </Group>
      {(() => {
        switch (fees.activeFee) {
          case 'Lump Sum':
            return <LumpSum fees={fees} dispatchProposal={dispatchProposal} />;
          case 'Retainer':
            return <Retainer fees={fees} dispatchProposal={dispatchProposal} />;
          case 'Hourly Rate':
            return <HourlyRate fees={fees} dispatchProposal={dispatchProposal} />;
          case 'Blended Rate':
            return <BlendedRate fees={fees} dispatchProposal={dispatchProposal} />;
          default:
            return null;
        }
      })()}
    </>
  );
}

function LumpSum({
  fees,
  dispatchProposal,
}: {
  fees: FeeProposal;
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();

  return (
    <NumberInput
      label={t('lumpSum')}
      value={fees.LumpSum || 0}
      onChange={(value) =>
        dispatchProposal({ type: PROPOSALACTIONS.EDIT_FEES, field: 'LumpSum', value })
      }
      w="150px"
    />
  );
}

function Retainer({
  fees,
  dispatchProposal,
}: {
  fees: FeeProposal;
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();
  return (
    <>
      {/* <h3>{t('retainer')}</h3> */}
      <Group w="100%" align="flex-end">
        <NumberInput
          label={t('retainerAmount')}
          value={(fees.Retainer && fees.Retainer.amount) || 0}
          onChange={(value) =>
            dispatchProposal({ type: PROPOSALACTIONS.UPDATE_FEES_RETAINER, field: 'amount', value })
          }
          step={100}
        />
        <Select
          label={t('billingFrequency')}
          data={[t('weekly'), t('monthly'), t('quarterly'), t('yearly')]}
          onChange={(value) =>
            dispatchProposal({ type: PROPOSALACTIONS.UPDATE_FEES_RETAINER, field: 'period', value })
          }
          allowDeselect={false}
          defaultValue="Monthly"
        />
      </Group>
    </>
  );
}

function HourlyRate({
  fees,
  dispatchProposal,
}: {
  fees: FeeProposal;
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();

  return (
    <>
      {/* <h3>{t('hourly')}</h3> */}
      <FeeTable
        fees={fees.HourlyRate || []}
        dispatchProposal={(action: any) => dispatchProposal(action)}
      />
    </>
  );
}

function BlendedRate({
  fees,
  dispatchProposal,
}: {
  fees: FeeProposal;
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();

  return (
    <>
      {/* <h3>{t('blendedRate')}</h3> */}
      <NumberInput
        w="150px"
        label={t('blendedRate')}
        value={fees.BlendedRate || 0}
        onChange={(value) =>
          dispatchProposal({ type: PROPOSALACTIONS.EDIT_FEES, field: 'BlendedRate', value })
        }
        step={100}
      />
    </>
  );
}
