import React, { useEffect, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';
import { Title, Tooltip, Button, Loader, Divider } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import LawyerTable from './LawyerTable';
import ExperienceTable from './ExperienceTable';
import { getLawyerList } from '@/services/proposalsRoutes';
import { authWebSocket } from '../../auth/authFetches';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { USERFEEDBACKACTIONS } from './reducers/userFeedbackReducer';

import { OtherData, Proposal } from '@/interfaces/types';
import { styles } from './styles';
import { OTHERDATAACTIONS } from './reducers/otherDataReducer';

export type ProposalRef = {
  handleGenerate: () => void;
  handleCancel: () => void;
};

const AIGeneratedSection = forwardRef(function AIGeneratedSection(
  {
    proposal,
    dispatchProposal,
    userFeedback,
    dispatchUserFeedback,
    otherData,
    dispatchOtherData,
  }: {
    proposal: Proposal;
    dispatchProposal: React.Dispatch<any>;
    userFeedback: any;
    dispatchUserFeedback: React.Dispatch<any>;
    otherData: OtherData;
    dispatchOtherData: React.Dispatch<any>;
  },
  ref: React.Ref<ProposalRef>
) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const { lawyers, experience, input, sector, constraints } = proposal;
  const { allLawyers, documentLanguage, neededLawyerCompletions, currentLawyerCompletions } =
    otherData;
  const { loadingLawyers, loadingExperiences, lawyerLoadingState, experienceLoadingState } =
    userFeedback;
  //   console.log('needed lawyer completions is', neededLawyerCompletions);
  //   console.log('current lawyer completions is', currentLawyerCompletions);

  // Fetch Lawyer List on mount
  useEffect(() => {
    const fetchLawyerList = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getLawyerList(accessToken);
        dispatchOtherData({ field: 'allLawyers', value: response });
      } catch (error) {
        console.error('Error fetching lawyer list:', error);
        alert('Failed to fetch lawyer list.');
      }
    };

    fetchLawyerList();
  }, [getAccessTokenSilently, dispatchOtherData]);

  useEffect(() => {
    dispatchUserFeedback({
      type: USERFEEDBACKACTIONS.SET_LOADING,
      field: 'loading',
      value: loadingLawyers || loadingExperiences,
    });
  }, [loadingLawyers, loadingExperiences]);

  const [ws, setWs] = React.useState<WebSocket | null>(null);

  useEffect(() => {
    if (currentLawyerCompletions >= neededLawyerCompletions && !loadingExperiences && ws) {
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, payload: false });
      dispatchOtherData({ field: 'currentLawyerCompletions', value: 0 });
      console.log('Lawyer loading completed.');
      console.log('Both tasks are done. Closing WebSocket.');
      ws.close();
    }
  }, [currentLawyerCompletions, neededLawyerCompletions, loadingExperiences, ws]);
  console.log(lawyers);
  // Updated lawyer message handler using useCallback
  const handleLawyerGenerationMessage = useCallback(
    (message: any) => {
      console.log('Lawyer generation message: ', message);
      console.log(lawyers);
      if (message.type === 'selected_lawyers') {
        const lawyerNames = message.data;
        lawyerNames.forEach((lawyerName: string) => {
          console.log(lawyerNames);
          console.log(lawyers.map((lawyer: any) => lawyer.name));
          // Use includes to check membership instead of "in"
          if (!lawyers.map((lawyer: any) => lawyer.name).includes(lawyerName)) {
            console.log('Adding lawyer:', lawyerName);
            dispatchProposal({
              type: PROPOSALACTIONS.ADD_LAWYER,
              payload: { bio: '', name: lawyerName },
            });
          }
        });
      } else if (message.type === 'bio_response') {
        const { lawyer, bio } = message.data;
        dispatchProposal({
          type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
          payload: { name: lawyer, bio },
        });
      } else if (message.type === 'completed') {
        dispatchOtherData({
          type: OTHERDATAACTIONS.INCREMENT_CURRENT_LAWYER_COMPLETIONS,
        });
      } else {
        dispatchUserFeedback({
          type: USERFEEDBACKACTIONS.SET_LAWYER_LOADING_STATE,
          value: message.type,
        });
      }
    },
    [lawyers, dispatchProposal, dispatchOtherData, dispatchUserFeedback]
  );

  const handleExperienceGenerationMessage = (message: any) => {
    console.log('experience generation message: ', message);
    if (message.type === 'selected_experiences') {
      for (const experience of message.data) {
        dispatchProposal({
          type: PROPOSALACTIONS.ADD_EXPERIENCE,
          payload: experience,
        });
      }
    } else if (message.type === 'completed') {
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
      console.log('Experiences loading completed.');
    } else {
      dispatchUserFeedback({
        type: USERFEEDBACKACTIONS.SET_EXPERIENCE_LOADING_STATE,
        value: message.type,
      });
    }
  };

  const handleRequiredLawyers = () => {
    if (constraints && constraints.requiredLawyers && constraints.requiredLawyers.length > 0) {
      for (let lawyerName of constraints.requiredLawyers) {
        if (!lawyers.map((lawyer) => lawyer.name).includes(lawyerName)) {
          console.log('here twice :<');
          dispatchProposal({
            type: PROPOSALACTIONS.ADD_LAWYER,
            payload: { name: lawyerName, bio: '' },
          });
          handleGetLawyerBio(lawyerName);
        }
      }
    }
  };

  const handleGetLawyerBio = (lawyerName: string) => {
    const generate = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const newws = authWebSocket(accessToken);
        setWs(newws);

        newws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'bio_response') {
            const { lawyer, bio } = data.data;
            dispatchProposal({
              type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
              payload: { name: lawyer, bio },
            });
          } else if (data.type === 'completed') {
            ws?.close();
            console.log('Lawyer loading completed.');
          }
        };

        newws.onopen = () => {
          newws.send(
            JSON.stringify({
              action: 'generateLawyers',
              lawyer_names: [lawyerName],
              language: documentLanguage,
              query: input,
            })
          );
        };
        return newws;
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
      }
    };
    generate();
  };

  const handleCancel = () => {
    if (ws) {
      ws.close();
      setWs(null);
    }
    dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
    dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
    dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
    dispatchOtherData({ field: 'currentLawyerCompletions', value: 0 });
  };

  const handleGenerate = () => {
    // Check first if you actually need to do any LLM-related stuff
    const lawyerGenerationNeeded =
      constraints && constraints.teamComposition && constraints.teamComposition.length > 0;
    const experienceGenerationNeeded =
      constraints && constraints.nExperiences && constraints.nExperiences > 0;
    if (!lawyerGenerationNeeded && !experienceGenerationNeeded) {
      // Only situation where anything would need doing from input is if there are required lawyers
      if (constraints.requiredLawyers && constraints.requiredLawyers.length > 0) {
        handleRequiredLawyers();
        return;
      } else {
        alert(t('teamCompositionComplaint'));
        return;
      }
    }

    // Set loading states for user feedback on generation process
    dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: true });
    if (lawyerGenerationNeeded) {
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: true });
    }
    if (experienceGenerationNeeded) {
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: true });
    }

    const generate = async () => {
      if (typeof input !== 'string') {
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
        console.error('Input must be a string');
        alert('Input must be a string');
        return;
      }

      handleRequiredLawyers();
      try {
        const accessToken = await getAccessTokenSilently();
        const newws = authWebSocket(accessToken);
        setWs(newws);

        newws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.source === 'offer_creation_lawyers') {
            handleLawyerGenerationMessage(data);
          } else if (data.source === 'offer_creation_experiences') {
            handleExperienceGenerationMessage(data);
          }
        };

        newws.onopen = () => {
          if (lawyerGenerationNeeded) {
            dispatchOtherData({
              field: 'neededLawyerCompletions',
              value: constraints.teamComposition!.length,
            });
            console.log('needed lawyer completions set to', constraints.teamComposition!.length);
            for (let item of constraints.teamComposition!) {
              if (item.team === 'Any') {
                newws.send(
                  JSON.stringify({
                    action: 'generateLawyers',
                    query: input.concat('Client Sector: ', sector),
                    language: documentLanguage,
                    team_constraint: '',
                    top_k: item.quantity,
                  })
                );
              } else {
                newws.send(
                  JSON.stringify({
                    action: 'generateLawyers',
                    query: input.concat('Client Sector: ', sector),
                    language: documentLanguage,
                    team_constraint: item.team,
                    top_k: item.quantity,
                  })
                );
              }
            }
          }
          if (experienceGenerationNeeded) {
            newws.send(
              JSON.stringify({
                action: 'generateExperiences',
                query: input,
                language: documentLanguage,
                top_k: constraints.nExperiences,
              })
            );
          }
        };
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
      }
    };
    generate();
  };

  useImperativeHandle(
    ref,
    () => ({
      handleGenerate,
      handleCancel,
    }),
    [handleGenerate, handleCancel]
  );

  return (
    <div>
      <div style={styles.flexRow}>
        <Title order={1}>{t('generatedByAI')}</Title>
        <Tooltip label={t('llmAssignedLawyers')} withArrow multiline position="right" w={220}>
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>

        {/* <div style={{ marginLeft: 'auto' }}>
          <Button
            color={loading ? 'orange' : 'cyan'}
            variant="light"
            leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
            style={{ marginRight: '0.5rem' }}
            onClick={() => {
              if (loading) {
                handleCancel();
              } else {
                handleGenerate();
              }
            }}
          >
            {loading ? t('cancelGeneration') : t('generateProposal')}
          </Button>
        </div> */}
      </div>
      {/* Container for Lawyers and Experience side-by-side */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr', // left column, divider, right column
          columnGap: '3rem', // increased gap between columns
          alignItems: 'start',
        }}
      >
        {/* Lawyers Section */}
        <div>
          <h2 style={{ display: 'flex', alignItems: 'center' }}>
            {t('lawyers')}
            {loadingLawyers && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                <Loader size="xs" color="blue" />
                <span style={styles.loaderText}>
                  {t('loading')} {lawyerLoadingState}
                </span>
              </div>
            )}
          </h2>
          <LawyerTable
            allLawyers={allLawyers}
            selectedLawyers={lawyers}
            dispatchProposal={dispatchProposal}
            otherData={otherData}
            proposal={proposal}
          />
        </div>

        {/* Vertical Divider */}
        <Divider orientation="vertical" style={{ height: '100%' }} />

        {/* Experience Section */}
        <div>
          <h2 style={{ display: 'flex', alignItems: 'center' }}>
            {t('experience')}
            {loadingExperiences && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                <Loader size="xs" color="blue" />
                <span style={styles.loaderText}>
                  {t('loading')} {experienceLoadingState}
                </span>
              </div>
            )}
          </h2>
          <ExperienceTable experiences={experience} dispatchProposal={dispatchProposal} />
        </div>
      </div>
    </div>
  );
});

export default AIGeneratedSection;
