import { FeeProposal, Experience } from '../types';
import { Proposal } from '@/interfaces/types';

export const PROPOSALACTIONS = {
    SET_FIELD: 'set_field',
    INITIALIZE: 'initialize',
    SET_INTRO_DEFAULT: 'set_intro_default',
    SET_CONSTRAINTS: 'set_constraints',
    ADD_LAWYER_CONSTRAINT: 'add_lawyer_constraint',
    REMOVE_LAWYER_CONSTRAINT: 'remove_lawyer_constraint',
    ADD_SENIORITY_CONSTRAINT: 'add_seniority_constraint',
    REMOVE_SENIORITY_CONSTRAINT: 'remove_seniority_constraint',
    ADD_TEAM_CONSTRAINT: 'add_team_constraint',
    REMOVE_TEAM_CONSTRAINT: 'remove_team_constraint',
    ADD_N_EXPERIENCE_CONSTRAINT: 'add_n_experience_constraint',
    ADD_FEES_HOURLY_RATE: 'add_fee_hourly_rate',
    REMOVE_FEES_HOURLY_RATE: 'remove_fee_hourly_rate',
    EDIT_FEES: 'edit_fees',
    UPDATE_FEES_RETAINER: 'update_fees_retainer',
    ADD_REPRESENTATIVE: 'add_representative',
    REMOVE_REPRESENTATIVE: 'remove_representative',
    ADD_LAWYER: 'add_lawyer',
    REMOVE_LAWYER: 'remove_lawyer',
    EDIT_LAWYER: 'edit_lawyer',
    EDIT_LAWYER_BIO: 'edit_lawyer_bio',
    REORDER_LAWYERS: 'reorder_lawyers',
    ADD_EXPERIENCE: 'add_experience',
    REMOVE_EXPERIENCE: 'remove_experience',
    EDIT_EXPERIENCE: 'edit_experience',
};

// Initial state structure
export const initialProposalState: Proposal = {
    id: '',
    title: 'New Proposal',
    input: '',
    sector: '',
    constraints: {
        nExperiences: 0
    },
    client: { name: '', company: '' },
    introDefault: '',
    intro: '',
    scope: '',
    assumptions: '',
    fees: { activeFee: 'Lump Sum' },
    representatives: [] as string[],
    lawyers: [] as { name: string; bio: string }[],
    experience: [] as Experience[],
    experienceIntro: '',
    proposalDate: new Date(),
};

export function proposalReducer(state: Proposal, action: any) {

    switch (action.type) {
        case PROPOSALACTIONS.SET_FIELD:
            return { ...state, [action.field]: action.value };

        case PROPOSALACTIONS.INITIALIZE:
            return {
                ...state,
                ...action.payload,
                introDefault: action.payload.introDefault || state.introDefault,
                intro: action.payload.intro || state.introDefault,
            };

        case PROPOSALACTIONS.SET_CONSTRAINTS:
            return { ...state, constraints: { ...state.constraints, [action.field]: action.value } };

        // case PROPOSALACTIONS.ADD_LAWYER_CONSTRAINT:
        //   return {
        //     ...state,
        //     constraints: {
        //       ...state.constraints,
        //       requiredLawyers: (state.constraints &&
        //         state.constraints.requiredLawyers && [
        //           ...state.constraints.requiredLawyers,
        //           action.value,
        //         ]) || [action.value],
        //     },
        //   };

        // case PROPOSALACTIONS.REMOVE_LAWYER_CONSTRAINT:
        //   return {
        //     ...state,
        //     constraints: {
        //       ...state.constraints,
        //       requiredLawyers:
        //         (state.constraints &&
        //           state.constraints.requiredLawyers &&
        //           state.constraints.requiredLawyers.filter((lawyer) => lawyer !== action.value)) ||
        //         [],
        //     },
        //   };
        // case PROPOSALACTIONS.ADD_SENIORITY_CONSTRAINT:
        //   return {
        //     ...state,
        //     constraints: {
        //       ...state.constraints,
        //       Seniority: (state.constraints &&
        //         state.constraints.Seniority && [...state.constraints.Seniority, action.payload]) || [
        //         action.payload,
        //       ],
        //     },
        //   };

        // case PROPOSALACTIONS.REMOVE_SENIORITY_CONSTRAINT:
        //   return {
        //     ...state,
        //     constraints: {
        //       ...state.constraints,
        //       Seniority:
        //         (state.constraints &&
        //           state.constraints.Seniority &&
        //           state.constraints.Seniority.filter((seniority) => seniority !== action.payload)) ||
        //         [],
        //     },
        //   };

        case PROPOSALACTIONS.ADD_TEAM_CONSTRAINT:
            return {
                ...state,
                constraints: {
                    ...state.constraints,
                    teamComposition: (state.constraints &&
                        state.constraints.teamComposition && [
                            ...state.constraints.teamComposition,
                            action.payload,
                        ]) || [action.payload],
                },
            };

        case PROPOSALACTIONS.REMOVE_TEAM_CONSTRAINT:
            return {
                ...state,
                constraints: {
                    ...state.constraints,
                    teamComposition:
                        (state.constraints &&
                            state.constraints.teamComposition &&
                            state.constraints.teamComposition.filter((team) => team !== action.payload)) ||
                        [],
                },
            };


        case PROPOSALACTIONS.ADD_N_EXPERIENCE_CONSTRAINT:
            return {
                ...state,
                constraints: {
                    ...state.constraints,
                    nExperiences: action.payload,
                },
            };

        // Dealing with fees
        case PROPOSALACTIONS.EDIT_FEES: // used to edit top-level (i.e. unnested) fields within the fees object e.g. currency, LumpSum, BlendedRate

            return {
                ...state,
                fees: {
                    ...state.fees,
                    [action.field]: action.value,
                },
            };

        case PROPOSALACTIONS.UPDATE_FEES_RETAINER:
            return {
                ...state,
                fees: {
                    ...state.fees,
                    Retainer: {
                        ...state.fees.Retainer,
                        [action.field]: action.value,
                    },
                },
            };

        case PROPOSALACTIONS.ADD_FEES_HOURLY_RATE:
            return {
                ...state,
                fees: {
                    ...state.fees,
                    HourlyRate: [...(state.fees.HourlyRate || []), action.payload],
                },
            };

        case PROPOSALACTIONS.REMOVE_FEES_HOURLY_RATE:
            return {
                ...state,
                fees: {
                    ...state.fees,
                    HourlyRate:
                        state.fees.HourlyRate &&
                        state.fees.HourlyRate.filter(
                            (rate) =>
                                !(rate.position == action.payload.position && rate.rate == action.payload.rate)
                        ),
                },
            };

        // Representatives

        // case PROPOSALACTIONS.ADD_REPRESENTATIVE:
        //   return { ...state, representatives: [...state.representatives, action.payload] };

        // case PROPOSALACTIONS.REMOVE_REPRESENTATIVE:
        //   return {
        //     ...state,
        //     representatives: state.representatives.filter((rep) => {
        //       for (let key in action.payload) {
        //         if (action.payload[key as keyof Representative] !== rep[key as keyof Representative]) {
        //           return true;
        //         }
        //       }
        //       return false;
        //     }),
        //   };

        // Dealing with Lawyers
        case PROPOSALACTIONS.ADD_LAWYER:
            if (state.lawyers.map((lawyer) => lawyer.name).includes(action.payload.name)) {
                return state;
            } else {
                return {
                    ...state,
                    lawyers: [
                        ...state.lawyers,
                        {
                            name: action.payload.name,
                            bio: action.payload.bio,
                        },
                    ],
                };
            }

        case PROPOSALACTIONS.REMOVE_LAWYER:
            let newLawyers = [...state.lawyers];
            const remove_index = newLawyers.findIndex((lawyer) => lawyer.name === action.payload);
            newLawyers.splice(remove_index, 1);
            return { ...state, lawyers: newLawyers };

        case PROPOSALACTIONS.EDIT_LAWYER:
            const { oldName, newName } = action.payload;
            const lawyerIndex2 = state.lawyers.findIndex((lawyer) => lawyer.name === oldName);
            const newLawyers2 = [...state.lawyers];
            newLawyers2[lawyerIndex2] = { name: newName, bio: '' };
            return { ...state, lawyers: newLawyers2 }


        case PROPOSALACTIONS.EDIT_LAWYER_BIO:
            const { name, bio } = action.payload;
            const lawyerIndex = state.lawyers.findIndex((lawyer) => lawyer.name === name);
            const newLawyers1 = [...state.lawyers];
            newLawyers1[lawyerIndex] = { name, bio: bio };

            return {
                ...state,
                lawyers: newLawyers1,
            };

        case PROPOSALACTIONS.REORDER_LAWYERS:
            return {
                ...state,
                lawyers: action.payload,
            }


        // Dealing with experiences
        case PROPOSALACTIONS.ADD_EXPERIENCE:
            if (action.payload) {
                let { title, description, client, team } = action.payload;
                return {
                    ...state,
                    experience: [
                        ...state.experience,
                        {
                            title: title || '',
                            description: description || '',
                            client: client || '',
                            team: team || '',
                        },
                    ],
                };
            } else {
                return {
                    ...state,
                    experience: [...state.experience, { title: '', description: '', client: '', team: '' }],
                };
            }

        case PROPOSALACTIONS.EDIT_EXPERIENCE:
            const { title, description, client, team, idx } = action.payload;
            if (idx === undefined) {
                console.log('ERROR: No index provided for experience edit');
                return state;
            }
            const updatedExperience = structuredClone(state.experience);
            updatedExperience[idx] = {
                title: title || state.experience[idx].title,
                description: description || state.experience[idx].description,
                client: client || state.experience[idx].client,
                team: team || state.experience[idx].team,
            };
            return { ...state, experience: updatedExperience };

        case PROPOSALACTIONS.REMOVE_EXPERIENCE:
            const index = action.payload;
            return { ...state, experience: state.experience.filter((_, i) => i !== index) };

        default:
            return state;
    }
}
