import { Paper, Button } from '@mantine/core';
import { Divider, Stack, Title, Center } from '@mantine/core';
import ProposalTable from './ProposalTable';
import { IconPlus } from '@tabler/icons-react'; // Correct import for the icon
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ProposalsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateNewClick = () => {
    const newPath = `${location.pathname}/edit-proposal`;
    const newId = Math.random().toString(36).substring(7);
    navigate(newPath, { state: { proposalId: newId } }); // Pass state here
  };

  return (
    <Stack w="80%" align="stretch">
      <Title>{t('proposals')}</Title>
      <Divider my="lg" />
      <Button
        color="green"
        mt="lg"
        style={{ width: 'fit-content' }}
        leftSection={<IconPlus size={16} />}
        onClick={handleCreateNewClick}
      >
        {t('createNew')}
      </Button>
      <Paper withBorder={true} radius="md" p="md">
        <Center>
          <ProposalTable />
        </Center>
      </Paper>
    </Stack>
  );
}
