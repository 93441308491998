import { styles } from './styles';
import axios from 'axios';
import {
  Textarea,
  Button,
  Loader,
  Tooltip,
  NumberInput,
  Select,
  MultiSelect,
  LoadingOverlay,
  Stack,
  Group,
  TextInput,
  SegmentedControl,
  Input,
  Grid, // <-- new import added
  Text,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import {
  removeFile,
  getFilelist,
  notifyFileUpload,
  getPresignedURLs,
} from '@/services/removeFiles';
import { Proposal, FileMetadata, PresignedURL, GetPresignedURLsResponse } from '@/interfaces/types';
import { compact } from 'lodash';
import EditableTable from '../../components/Tables/EditableTable';
import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchExpertise } from '@/services/proposalsRoutes';
import ExpandingTextArea from '@/components/ExpandingTextArea';

export default function CaseInfoSection({
  proposal,
  dispatchProposal,
  userFeedback,
  dispatchUserFeedback,
  otherData,
  dispatchOtherData,
}: {
  proposal: Proposal;
  dispatchProposal: React.Dispatch<any>;
  userFeedback: any;
  dispatchUserFeedback: React.Dispatch<any>;
  otherData: any;
  dispatchOtherData: React.Dispatch<any>;
  //   reference: any;
  onlyShowRequestArea?: boolean;
  onlyShowConstraints?: boolean;
}) {
  const { title, input, sector, proposalDate, client, intro, introDefault } = proposal;
  const { allLawyers, expertise, seniority, documentLanguage } = otherData;
  const { loading } = userFeedback;
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [isLLMLoading, setIsLLMLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getExpertise() {
      const accessToken = await getAccessTokenSilently();
      const response = await fetchExpertise(accessToken);
      dispatchOtherData({
        field: 'expertise',
        value: ['Any', ...response],
      });
    }
    getExpertise();
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLLMLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();

        // Prepare file metadata for all accepted files
        const filesMetadata = acceptedFiles.map((file) => ({
          filename: file.name,
          filetype: file.type || 'application/octet-stream',
        }));

        // Request pre-signed URLs for all files
        const response: GetPresignedURLsResponse = await getPresignedURLs(
          accessToken,
          filesMetadata,
          'documents-for-case-info'
        );
        const urls: PresignedURL[] = response.urls; // Now TypeScript knows the type of 'urls'

        // Map filenames to their corresponding URLs
        const urlMap = new Map(
          urls.map((item: { filename: string; url: string }) => [item.filename, item.url])
        );

        const successfullyUploadedFiles: string[] = [];
        const unsuccessfullyUploadedFiles: string[] = [];
        const summaries: string[] = [];

        // Upload files concurrently using Promise.all
        const uploadPromises = acceptedFiles.map(async (file) => {
          const url = urlMap.get(file.name);
          if (!url) {
            console.error(`No pre-signed URL for file ${file.name}`);
            unsuccessfullyUploadedFiles.push(file.name);
            return;
          }
          try {
            await axios.put(url, file, {
              headers: {
                'Content-Type': file.type || 'application/octet-stream',
              },
            });

            const response = await notifyFileUpload(
              accessToken,
              file.name,
              'process_case_info_file'
            );
            console.log(response);
            const summary = response.data.summary;
            console.log(summary);
            successfullyUploadedFiles.push(file.name);
            const formattedSummary = `${file.name}:\n${summary}`;
            summaries.push(formattedSummary);
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            unsuccessfullyUploadedFiles.push(file.name);
          }
        });

        await Promise.all(uploadPromises);
        const combinedSummary = summaries.join('\n\n');
        dispatchProposal({
          type: PROPOSALACTIONS.SET_FIELD,
          field: 'input',
          value:
            proposal.input.length > 0 ? proposal.input + '\n\n' + combinedSummary : combinedSummary,
        });

        // Provide user feedback
        let message = '';
        if (successfullyUploadedFiles.length > 0) {
          message += `${t('successfulFileUpload')} ${successfullyUploadedFiles.join(',\n')}.\n`;
        }
        if (unsuccessfullyUploadedFiles.length > 0) {
          message += `${t('unsuccessfulFileUpload')} ${unsuccessfullyUploadedFiles.join(',\n')}.`;
        }
        alert(message || t('noFilesUploaded'));
      } catch (error) {
        console.error('Error during file upload:', error);
        alert(t('errorUploadingFiles'));
      } finally {
        setIsLLMLoading(false);
      }
    },
    [getAccessTokenSilently, t, proposal.input, dispatchProposal]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'], // Accept PDF files
      // 'text/plain': ['.txt'], // Accept text files
      'application/msword': ['.doc'], // Accept .doc files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], // Accept .docx files
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'], // Accept .pptx files
      'image/jpeg': ['.jpg', '.jpeg'], // Accept JPEG files
      'image/png': ['.png'], // Accept PNG files
    },
  });

  // Update introDefault on language change
  useEffect(() => {
    const updateIntroDefault = () => {
      const updatedIntro = documentLanguage === t('english') ? introLetterEng : introLetterPol;
      dispatchProposal({
        type: PROPOSALACTIONS.SET_FIELD,
        field: 'introDefault',
        value: updatedIntro,
      });
      dispatchProposal({ type: PROPOSALACTIONS.SET_FIELD, field: 'intro', value: updatedIntro });
    };

    updateIntroDefault();
  }, [documentLanguage]);

  var displayDate = new Date();
  if (typeof proposalDate === 'string') {
    displayDate = new Date(proposalDate);
  } else if (proposalDate instanceof Date) {
    displayDate = proposalDate;
  }

  return (
    <Stack p="md">
      {/* <div style={styles.flexRow}>
        <Title order={3}>{t('caseInfo')}</Title>
        <Tooltip label={t('provideCaseInfo')} withArrow multiline w={220} position="right">
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div> */}

      <Grid style={{ columnGap: '1rem', rowGap: '2rem' }}>
        <Grid.Col span={4}>
          <TextInput
            label={t('title')}
            size="sm"
            value={title}
            placeholder={t('addTitleHere')}
            onChange={(e) =>
              dispatchProposal({
                type: PROPOSALACTIONS.SET_FIELD,
                field: 'title',
                value: e.target.value,
              })
            }
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput
            label={t('clientCompany')}
            style={{ width: '100%' }}
            value={client.company}
            onChange={(e) => {
              const newCompany = e.target.value;
              if (!checkIntroChangedTooMuch(intro, introDefault, t('introLine1'), t('introRest'))) {
                const newIntro = introDefault
                  .replace(/__company__/g, newCompany)
                  .replace(/__name__/g, client.name);
                dispatchProposal({
                  type: PROPOSALACTIONS.SET_FIELD,
                  field: 'intro',
                  value: newIntro,
                });
              }
              dispatchProposal({
                type: PROPOSALACTIONS.SET_FIELD,
                field: 'client',
                value: { ...client, company: newCompany },
              });
            }}
          />
        </Grid.Col>
        <Grid.Col span={4} style={{ alignSelf: 'end' }}>
          <Text fz="sm" fw="500">
            {t('language')}
          </Text>
          <SegmentedControl
            maw="300px"
            size="sm"
            data={[
              { label: 'PL', value: 'pl' },
              { label: 'EN', value: 'en' },
            ]}
            value={documentLanguage}
            onChange={(value) => {
              dispatchOtherData({
                type: 'SET_FIELD',
                field: 'documentLanguage',
                value: value,
              });
            }}
            color="blue"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label={t('clientRepresentative')}>
            <TextInput
              value={client.name}
              onChange={(e) => {
                const newName = e.target.value;
                if (
                  !checkIntroChangedTooMuch(intro, introDefault, t('introLine1'), t('introRest'))
                ) {
                  const newIntro = introDefault
                    .replace(/__name__/g, newName)
                    .replace(/__company__/g, client.company);
                  dispatchProposal({
                    type: PROPOSALACTIONS.SET_FIELD,
                    field: 'intro',
                    value: newIntro,
                  });
                }
                dispatchProposal({
                  type: PROPOSALACTIONS.SET_FIELD,
                  field: 'client',
                  value: { ...client, name: newName },
                });
              }}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Textarea
            label={t('clientSector')}
            value={sector || ''}
            onChange={(e) => {
              dispatchProposal({
                type: PROPOSALACTIONS.SET_FIELD,
                field: 'sector',
                value: e.target.value,
              });
            }}
            autosize
            minRows={1}
            maxRows={8}
            style={{ overflowY: 'auto' }}
            leftSectionWidth={1}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            label={t('proposalDate')}
            type="date"
            value={displayDate.toISOString().slice(0, 10)}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              dispatchProposal({
                type: PROPOSALACTIONS.SET_FIELD,
                field: 'proposalDate',
                value: selectedDate,
              });
            }}
          />
        </Grid.Col>
      </Grid>

      <div
        {...getRootProps()}
        style={{ border: '2px dashed #0087F7', padding: '1px', textAlign: 'center' }}
      >
        <input {...getInputProps()} />
        <p>{t('provideContextFile')}</p>
      </div>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay
          visible={isLLMLoading}
          zIndex={1}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <ExpandingTextArea
          value={input}
          placeholder={t('addYourInputTextHere')}
          onChange={(e) =>
            dispatchProposal({
              type: PROPOSALACTIONS.SET_FIELD,
              field: 'input',
              value: e.target.value,
            })
          }
        />
        {/* <Textarea
          value={input}
          placeholder={t('addYourInputTextHere')}
          onChange={(e) => {
            dispatchProposal({
              type: PROPOSALACTIONS.SET_FIELD,
              field: 'input',
              value: e.target.value,
            });
          }}
          variant="filled"
          autosize
          minRows={6}
        /> */}
      </div>
    </Stack>
  );
}

// Utility function to check intro changes
function checkIntroChangedTooMuch(
  intro: string,
  introDefault: string,
  line1: string,
  theRest: string
) {
  const isLine1Present = intro.includes(line1);
  const isRestPresent = intro.includes(theRest);
  const isLengthSimilar = Math.abs(intro.length - introDefault.length) < 60;

  return !(isLine1Present && isRestPresent && isLengthSimilar);
}

const introLetterEng = `
    Dear _____
    
    thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for _____.
    
    In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.
    
    In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 
    
    If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.
    
    Kind regards,
    `;

const introLetterPol = `
  Szanowna Pani/Panie _____
  
  dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]
  
  W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.
  
  W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.
  
  W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.
  
  Z poważaniem`;
