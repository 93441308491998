import { Table, Anchor, Button, Input, Center } from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProposalList, deleteProposal } from '@/services/proposalsRoutes';
import { IconX, IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { FeeProposal, HourlyRate } from './types';
import { useTranslation } from 'react-i18next';
import { PROPOSALACTIONS } from './reducers/proposalReducer';

// A componenet that generates a table row when given a row from proposalData
function ProposalRow({
  row,
  dispatchProposal,
}: {
  row: HourlyRate;
  dispatchProposal: React.Dispatch<any>;
}) {
  const handleDeleteClick = () => {
    async function deleteProposalData() {
      dispatchProposal({ type: PROPOSALACTIONS.REMOVE_FEES_HOURLY_RATE, payload: row });
    }
    deleteProposalData();
  };

  return (
    <Table.Tr key={row.position}>
      <Table.Td>{row.position}</Table.Td>
      <Table.Td>{row.rate}</Table.Td>
      <Table.Td style={{ textAlign: 'right' }}>
        <Center>
          <Button variant="light" color="red" size="compact-xs" onClick={handleDeleteClick}>
            <IconX size={16} />
          </Button>
        </Center>
      </Table.Td>
    </Table.Tr>
  );
}

export default function FeeTable({
  fees,
  dispatchProposal,
}: {
  fees: HourlyRate[];
  dispatchProposal: React.Dispatch<any>;
}) {
  const { t } = useTranslation();

  const [newRow, setNewRow] = useState<HourlyRate>({ position: '', rate: 0 });

  const handleAddRow = () => {
    dispatchProposal({ type: PROPOSALACTIONS.ADD_FEES_HOURLY_RATE, payload: newRow });
    setNewRow({ position: '', rate: 0 });
    // Update the fees state with the new row
    // You can use a callback function here to update the fees state
  };

  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRow({ ...newRow, position: e.target.value });
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRow({ ...newRow, rate: parseFloat(e.target.value) });
  };

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t('position')}</Table.Th>
            <Table.Th>{t('rate')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {fees &&
            fees.map((row) => (
              <ProposalRow key={row.position} row={row} dispatchProposal={dispatchProposal} />
            ))}
          <Table.Tr>
            <Table.Td>
              <Input value={newRow.position} onChange={handlePositionChange} />
            </Table.Td>
            <Table.Td>
              <Input type="number" value={newRow.rate} onChange={handleRateChange} />
            </Table.Td>
            <Table.Td>
              <Center>
                <Button variant="light" color="green" size="sm" onClick={handleAddRow}>
                  {t('addRow')}
                </Button>
              </Center>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
