import React, { useEffect } from 'react';
import { Container, Title, Text, Button, Space, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const MobileNotSupported: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 768) {
        // Adjust threshold as needed
        navigate('/');
      }
    };

    // Run check on mount
    checkScreenSize();

    // Optionally, listen for window resizes
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, [navigate]);

  return (
    <Stack align="center" style={{ textAlign: 'center', marginTop: '5rem' }}>
      <Title order={2}>Mobile Not Supported</Title>
      <Space h="md" />
      <Text size="lg" w="80%">
        Our application is optimized for desktop usage. Please access this site from a larger
        screen.
      </Text>
      {/* <Space h="xl" />
      <Button size="md" onClick={() => navigate('/')}>
        Return to Home
      </Button> */}
    </Stack>
  );
};

export default MobileNotSupported;
