import { useReducer, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, Title, ScrollArea, Button, Loader } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { proposalReducer, initialProposalState, PROPOSALACTIONS } from './reducers/proposalReducer';
import { userFeedbackReducer, initialUserFeedbackState } from './reducers/userFeedbackReducer';
import { otherDataReducer, initialOtherState } from './reducers/otherDataReducer';

import CaseInfoSection from './CaseInfoSection';
import Requirements from './Requirements';
import HumanGeneratedSection from './HumanGeneratedSection';

import { fetchProposal, getLawyerList } from '@/services/proposalsRoutes';
import AIGeneratedSection, { ProposalRef } from './AIGeneratedSection';
import { IconPlus } from '@tabler/icons-react';
import ActionButtons from './ActionButtons';

export default function EditProposalPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const [leftContainerHeight, setLeftContainerHeight] = useState(0);
  const ref = useRef<ProposalRef | null>(null);

  // Setting up reducers
  const [proposal, dispatchProposal] = useReducer(proposalReducer, initialProposalState);
  const [userFeedback, dispatchUserFeedback] = useReducer(
    userFeedbackReducer,
    initialUserFeedbackState
  );
  const [otherData, dispatchOtherData] = useReducer(otherDataReducer, initialOtherState);

  const { title } = proposal;
  const { loading } = userFeedback;
  const { proposalId } = location.state as { proposalId: string };

  // Fetch Proposal Data
  useEffect(() => {
    async function fetchData() {
      try {
        const accessToken = await getAccessTokenSilently();
        const fetchedData = await fetchProposal(accessToken, proposalId);
        if (fetchedData) {
          dispatchProposal({
            type: PROPOSALACTIONS.INITIALIZE,
            payload: {
              id: fetchedData.id,
              title: fetchedData.title,
              input: fetchedData.input,
              sector: fetchedData.sector,
              constraints: fetchedData.constraints,
              client: fetchedData.client,
              intro: fetchedData.intro,
              representatives: fetchedData.representatives || [],
              scope: fetchedData.scope,
              assumptions: fetchedData.assumptions,
              fees: fetchedData.fees || [],
              lawyers: fetchedData.lawyers || {},
              experienceIntro: fetchedData.experienceIntro || '',
              experience: fetchedData.experience || [],
              proposalDate: fetchedData.proposalDate,
            },
          });
        }
      } catch (error) {
        console.error('Error fetching proposal:', error);
        alert('Failed to fetch proposal data.');
      }
    }
    fetchData();
  }, [proposalId, getAccessTokenSilently]);

  // Fetch Lawyer List
  useEffect(() => {
    async function fetchLawyerList() {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getLawyerList(accessToken);
        dispatchOtherData({ field: 'allLawyers', value: response });
      } catch (error) {
        console.error('Error fetching lawyer list:', error);
        alert('Failed to fetch lawyer list.');
      }
    }
    fetchLawyerList();
  }, [getAccessTokenSilently]);

  // Use ResizeObserver to update left container height (on refresh and size changes)
  useEffect(() => {
    if (!leftContainerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setLeftContainerHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(leftContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <Box
        mx="5%"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', // two equal-width columns
          gap: '2rem',
        }}
      >
        {/* Left Column Container */}
        <div style={{ minWidth: 0 }}>
          <div
            ref={leftContainerRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              minWidth: 0, // allow it to shrink evenly
            }}
          >
            <Card
              style={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <CaseInfoSection
                proposal={proposal}
                dispatchProposal={dispatchProposal}
                userFeedback={userFeedback}
                dispatchUserFeedback={dispatchUserFeedback}
                otherData={otherData}
                dispatchOtherData={dispatchOtherData}
              />
            </Card>
            <Card
              style={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Requirements
                proposal={proposal}
                dispatchProposal={dispatchProposal}
                userFeedback={userFeedback}
                dispatchUserFeedback={dispatchUserFeedback}
                otherData={otherData}
                dispatchOtherData={dispatchOtherData}
              />
            </Card>
          </div>
        </div>

        {/* Right Column with ScrollArea */}
        <Card
          style={{
            border: '1px solid #ccc',
            borderRadius: '10px',
            width: '100%',
            minWidth: 0,
            height: leftContainerHeight ? `${leftContainerHeight}px` : '200px',
            boxSizing: 'border-box',
          }}
          p={0}
        >
          <ScrollArea
            style={{ height: '100%', width: '100%', minWidth: 0, boxSizing: 'border-box' }}
            p="md"
          >
            <HumanGeneratedSection
              proposal={proposal}
              dispatchProposal={dispatchProposal}
              userFeedback={userFeedback}
              dispatchUserFeedback={dispatchUserFeedback}
              otherData={otherData}
              dispatchOtherData={dispatchOtherData}
            />
          </ScrollArea>
        </Card>
        <Card
          style={{
            gridColumn: '1 / -1', // span both columns
            // border: '1px solid #ccc',
            // borderRadius: '10px',
            width: '100%',
            mih: '100px',
            boxSizing: 'border-box',
          }}
        >
          {/* Add generate proposal button */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color={loading ? 'orange' : 'cyan'}
              variant="light"
              size="md"
              leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                console.log(ref.current);
                if (ref.current) {
                  if (loading) {
                    ref.current.handleCancel();
                  } else {
                    ref.current.handleGenerate();
                  }
                }
              }}
            >
              {loading ? t('cancelGeneration') : t('generateProposal')}
            </Button>
          </div>
        </Card>

        {/* Additional Box spanning both columns */}
        <Card
          style={{
            gridColumn: '1 / -1', // span both columns
            border: '1px solid #ccc',
            borderRadius: '10px',
            width: '100%',
            mih: '200px',
            boxSizing: 'border-box',
          }}
        >
          <AIGeneratedSection
            proposal={proposal}
            dispatchProposal={dispatchProposal}
            userFeedback={userFeedback}
            dispatchUserFeedback={dispatchUserFeedback}
            otherData={otherData}
            dispatchOtherData={dispatchOtherData}
            ref={ref}
          />
        </Card>

        <Card
          style={{
            gridColumn: '1 / -1', // span both columns
            // border: '1px solid #ccc',
            // borderRadius: '10px',
            width: '100%',
            mih: '100px',
            boxSizing: 'border-box',
          }}
        >
          <ActionButtons
            proposal={proposal}
            userFeedback={userFeedback}
            otherData={otherData}
            dispatchUserFeedback={dispatchUserFeedback}
          />
        </Card>
      </Box>
    </>
  );
}
