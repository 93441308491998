import { Textarea } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ExpandingTextArea({
  value,
  placeholder,
  onChange,
  min_max_rows = 6,
  disabled = false,
}: {
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  min_max_rows?: number;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [maxRows, setMaxRows] = useState<number | undefined>(min_max_rows);
  return (
    <Textarea
      value={value}
      placeholder={placeholder}
      onFocus={() => setMaxRows(undefined)}
      onBlur={() => setMaxRows(min_max_rows)}
      onChange={onChange}
      variant="filled"
      autosize
      minRows={min_max_rows}
      maxRows={maxRows}
      disabled={disabled}
    />
  );
}
